import React from 'react';
import { ListItemAvatar, Stack, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { TpAnalyticsEvent, useAnalytics } from '@noah-labs/fe-shared-feature-analytics';
import {
  DefaultListItemText,
  ElevatedCardListItem,
  ProgressBar,
  QuestBadge,
  styledMixins,
} from '@noah-labs/fe-shared-ui-components';
import { isQuestPerpetual } from '../../data/utils';
import type { TpQuestListItem } from '../../types';
import { FlashTimer } from '../flashTimer/FlashTimer';

export type PpQuestListItem = {
  quest: TpQuestListItem | null;
};

const ListItemAvatarStyled = styled(ListItemAvatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  borderRadius: theme.borderRadius.sm,
  height: '60px',
  margin: theme.spacing(0, 2, 0, 0),
  width: '60px',
  ...styledMixins.colCentered,
}));

const buttonSx = {
  ':hover': {
    backgroundColor: 'inherit',
  },
  p: 0,
  width: '100%',
};

export function QuestListItem({ quest }: PpQuestListItem): React.ReactElement | null {
  const theme = useTheme();
  const { track } = useAnalytics();

  if (!quest) {
    return null;
  }

  const isActiveFlash = quest.ends && !quest.completed;

  const questIsPerpetual = isQuestPerpetual(quest.questId);

  return (
    <ElevatedCardListItem
      buttonSx={buttonSx}
      href={quest.href}
      sx={
        isActiveFlash
          ? {
              border: `1px solid ${theme.palette.brand.light}`,
              borderRadius: theme.borderRadius.md,
            }
          : undefined
      }
      onClick={(): void => {
        track(TpAnalyticsEvent.QuestClicked, {
          id: quest.questId,
        });
      }}
    >
      <Stack flexGrow={1}>
        <Stack sx={{ padding: 2 }}>
          <Stack alignItems="center" direction="row" justifyContent="center">
            {quest.badgeId && (
              <ListItemAvatarStyled>
                <QuestBadge badgeId={quest.badgeId} />
              </ListItemAvatarStyled>
            )}
            <DefaultListItemText
              noWrap={false}
              primary={quest.title}
              secondary={quest.completed ? 'Completed' : quest.activeTaskDescription}
              secondaryTypographyProps={{ color: 'text.light' }}
            />
            <Typography
              alignSelf="center"
              color="text.header"
              minWidth={80}
              textAlign="end"
              variant="paragraphBodyMBold"
            >
              {quest.np} pts
            </Typography>
          </Stack>

          {!questIsPerpetual && (
            <Stack alignItems="center" direction="row" spacing={2} sx={{ mt: 1 }}>
              <ProgressBar progress={quest.progress / quest.requiredProgress} />
              <Typography color="text.light" variant="paragraphBodyS">
                {quest.progress}/{quest.requiredProgress}
              </Typography>
            </Stack>
          )}
        </Stack>
        {isActiveFlash && <FlashTimer ends={quest.ends} />}
      </Stack>
    </ElevatedCardListItem>
  );
}
