import { generatePath } from '@noah-labs/fe-shared-ui-components';
import type { TpAddressData } from '@noah-labs/fe-shared-ui-components/crypto';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { AccountType, Network } from '@noah-labs/shared-schema-gql';

export function getNextUrl(result: TpAddressData): string {
  const params = {
    accountType: AccountType.Current,
    currencyCode: result.currencyCode,
    network: result.network,
  };

  switch (result.network) {
    case Network.Bitcoin:
    case Network.BitcoinTest:
    case Network.Tron:
    case Network.TronTestShasta: {
      const withdrawNext = result.amount
        ? walletRoutes().send.confirm
        : walletRoutes().send.enterAmount;
      return generatePath(withdrawNext, params);
    }

    case Network.Lightning:
    case Network.LightningTest: {
      // if the expiryTime is up, redirect to invoice expired
      if (result.expiryTime && result.expiryTime - Date.now() <= 0) {
        return generatePath(walletRoutes().send.lightning.invoiceExpired, params);
      }

      // if there's an amount, we can skip the amount entry screen in LN
      const lightningNext = result.amount
        ? walletRoutes().send.lightning.confirm
        : walletRoutes().send.lightning.enterAmount;
      return generatePath(lightningNext, params);
    }

    // network can be undefined when an address is multi network
    case undefined: {
      if (!result.address) {
        throw new Error('Unable to generate url, unknown address');
      }

      return generatePath(walletRoutes().send.network, params);
    }
    default:
      throw new Error('Unable to generate url, unknown network');
  }
}
