import { Fragment } from 'react';
import { Stack } from '@mui/material';
import {
  AppFooter,
  AppMain,
  Balance,
  PrimaryButton,
  SceneMain,
  TruncatedText,
} from '@noah-labs/fe-shared-ui-components';
import type { TpAddressData } from '@noah-labs/fe-shared-ui-components/crypto';
import type {
  TpCryptoCurrencyUI,
  TpCryptoNetworkUI,
  TpFiatCurrencyUI,
} from '@noah-labs/fe-shared-ui-shared';
import type { TpAmount } from '@noah-labs/shared-currencies';
import type { CurrencyDisplayType, CurrencyUnit } from '@noah-labs/shared-schema-gql';
import { AddressSummaryCard } from '../cards/AddressSummaryCard';
import {
  getCurrencyAmountSlots,
  type TpCurrencyAmountSlots,
} from '../utils/getCurrencyAmountSlots';

export type PpConfirmScene = {
  FeeSlot?: React.ReactElement;
  TotalAmountSlots?: TpCurrencyAmountSlots;
  addressData?: TpAddressData;
  cryptoAmount: TpAmount;
  cryptoCurrency: TpCryptoCurrencyUI;
  cryptoNetwork: TpCryptoNetworkUI;
  cryptoUnit: CurrencyUnit | undefined;
  ctaButtonLabel?: string;
  faqLink?: string;
  fiatAmount: TpAmount;
  fiatCurrency: TpFiatCurrencyUI | undefined;
  isCtaDisabled?: boolean;
  isLoading: boolean;
  onConfirm: () => Promise<void>;
  primaryCurrency: CurrencyDisplayType | undefined;
};

export function ConfirmScene({
  addressData,
  cryptoAmount,
  cryptoCurrency,
  cryptoNetwork,
  cryptoUnit,
  ctaButtonLabel = 'Send Now',
  FeeSlot,
  fiatAmount,
  fiatCurrency,
  isCtaDisabled = false,
  isLoading,
  onConfirm,
  primaryCurrency,
  TotalAmountSlots,
}: PpConfirmScene): React.ReactElement {
  const Amounts = getCurrencyAmountSlots({
    cryptoAmount,
    cryptoCurrency,
    cryptoUnit,
    fiatAmount,
    fiatCurrency,
    primaryCurrency,
    roundDown: true,
  });

  return (
    <Fragment>
      <AppMain>
        <SceneMain dataQa="confirm" maxWidth="xs">
          <Stack spacing={6}>
            <Balance {...Amounts} />

            {addressData && (
              <AddressSummaryCard
                address={<TruncatedText prefix={12} text={addressData.address} />}
                cryptoCurrency={cryptoCurrency}
                cryptoNetwork={cryptoNetwork}
                FeeSlot={FeeSlot}
                TotalAmountSlot={TotalAmountSlots || Amounts}
              />
            )}
          </Stack>
        </SceneMain>
      </AppMain>
      <AppFooter>
        <PrimaryButton
          color="primaryBrand"
          data-qa="confirm-button"
          disabled={isCtaDisabled}
          loading={isLoading}
          onClick={onConfirm}
        >
          {ctaButtonLabel}
        </PrimaryButton>
      </AppFooter>
    </Fragment>
  );
}
