import { useCallback, useEffect, useState } from 'react';
import { disableRefetchRetry } from '@noah-labs/fe-shared-data-access-shared';
import { useLnAddressProxyQuery } from '@noah-labs/fe-shared-data-access-wallet';
import { useFeature } from '@noah-labs/fe-shared-feature-user';
import type { TpAddressForm } from '@noah-labs/fe-shared-ui-address';
import { isAddressCustomError, parseCurrencyAddressData } from '@noah-labs/fe-shared-ui-address';
import {
  ApiUnknown,
  LnurlErrorAlert,
  parseMarketingUrl,
  usePushAlert,
} from '@noah-labs/fe-shared-ui-components';
import type {
  TpAddressData,
  TpLightningAddressData,
} from '@noah-labs/fe-shared-ui-components/crypto';
import { useRouter } from '@noah-labs/fe-shared-ui-shared';
import type { CurrencyCode } from '@noah-labs/shared-schema-gql';
import { Feature } from '@noah-labs/shared-schema-gql';
import { getErrorMessage } from '@noah-labs/shared-util-vanilla';
import type { UseFormSetError } from 'react-hook-form';
import { getConfig } from '../config';

type TpUseAddressData = {
  addressData: TpAddressData | undefined;
  error: unknown;
  handleAddress: (
    addressInput: string,
    currencyCode: CurrencyCode,
    setError: UseFormSetError<TpAddressForm>,
  ) => void;
  isLoading: boolean;
};

export function useAddressData(): TpUseAddressData {
  const { isProd } = getConfig();
  const [data, setData] = useState<TpAddressData | undefined>(undefined);

  const pushAlert = usePushAlert();
  const { push } = useRouter();

  const { feature: withdrawFF } = useFeature(Feature.Withdraw);

  const { address, addressType, lnUrlLink } = data || {};
  const isLnAddress = addressType === 'lnaddress';
  const isLnUrl = addressType === 'lnurl';

  const {
    data: lnAddressData,
    error: lnAddressError,
    isFetching: isLnAddressFetching,
  } = useLnAddressProxyQuery(
    {
      Input: {
        LightningAddress: isLnAddress ? address : undefined,
        LnurlLink: lnUrlLink,
      },
    },
    {
      enabled: isLnAddress || isLnUrl,
      ...disableRefetchRetry,
    },
  );

  useEffect(() => {
    if (!lnAddressData) {
      return;
    }

    const { lightningAddressProxy } = lnAddressData;
    switch (lightningAddressProxy.__typename) {
      case 'LightningAddressProxySuccess': {
        // safe to do a cast here because we know it's a ln address or lnurl at this point
        setData((prev) => ({ ...(prev as TpLightningAddressData), lightningAddressProxy }));
        break;
      }
      case 'LnurlError': {
        setData(undefined);
        pushAlert(LnurlErrorAlert(lightningAddressProxy));
        break;
      }

      default:
        pushAlert(ApiUnknown);
        break;
    }
  }, [lnAddressData, pushAlert, setData]);

  useEffect(() => {
    if (!lnAddressError) {
      return;
    }
    setData(undefined);
  }, [lnAddressError]);

  const handleAddress = useCallback(
    (
      addressInput: string,
      currencyCode: CurrencyCode,
      setError: UseFormSetError<TpAddressForm>,
    ) => {
      try {
        if (!withdrawFF?.Networks || !addressInput) {
          setData(undefined);
          return;
        }

        const marketingUrl = parseMarketingUrl(addressInput);
        if (marketingUrl) {
          push(marketingUrl);
          return;
        }

        const parsedAddressData = parseCurrencyAddressData({
          address: addressInput,
          availableNetworks: withdrawFF.Networks,
          currencyCode,
          isProd,
        });

        setData(parsedAddressData);
      } catch (err) {
        setData(undefined);

        const error = getErrorMessage(err);

        if (isAddressCustomError(error)) {
          setError('address', {
            type: error,
          });
          return;
        }

        pushAlert({
          dismissable: true,
          key: 'addressError',
          message: getErrorMessage(error),
          preventDuplicate: true,
          severity: 'error',
        });
      }
    },
    [push, withdrawFF?.Networks, pushAlert, isProd],
  );

  return {
    addressData: data,
    error: lnAddressError,
    handleAddress,
    isLoading: isLnAddressFetching,
  };
}
