import { useRef } from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import { TpAnalyticsEvent, useAnalytics } from '@noah-labs/fe-shared-feature-analytics';
import { AccountLimitsIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/AccountLimitsIcon';
import { BitcoinIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/BitcoinIcon';
import { CreditCardIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CreditCardIcon';
import { DiscordIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/DiscordIcon';
import { DocumentIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/DocumentIcon';
import { EyeIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/EyeIcon';
import { FaqIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/FaqIcon';
import { InfoIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/InfoIcon';
import { KeyholeIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/KeyholeIcon';
import { LinkIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/LinkIcon';
import { LockIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/LockIcon';
import { LogoutIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/LogoutIcon';
import { ProfileIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ProfileIcon';
import { TwitterIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/TwitterIcon';
import {
  AppContainer,
  AppHeader,
  AppMain,
  DefaultAvatar,
  DefaultListItemText,
  ListSection,
  MuiSvgIcon,
  PrimaryMenuIcon,
  PrimaryMenuItem,
  SceneMain,
  SceneParagraph,
  TextOrSkeleton,
} from '@noah-labs/fe-shared-ui-components';
import type {
  TpCryptoCurrencyUI,
  TpDialogToggle,
  TpFiatCurrencyUI,
} from '@noah-labs/fe-shared-ui-shared';
import { authRoutes, kycRoutes, userRoutes } from '@noah-labs/fe-shared-util-routes';
import { webLinks } from '@noah-labs/shared-constants';
import type { Maybe } from '@noah-labs/shared-schema-gql';
import { Feature } from '@noah-labs/shared-schema-gql';
import { Helmet } from 'react-helmet';
import { webConfigBrowser } from '../../../webConfigBrowser';
import { ReferFriendsSocialShareCard } from '../../rewards/components/cards/ReferFriendsSocialShareCard';
import { shareButtons } from '../../rewards/data/social';
import { LightningAddressDialog } from '../components/dialogs/LightningAddressDialog';
import { LnUrlChip } from '../components/LnUrlChip/LnUrlChip';
import { RestrictedFeature } from '../components/RestrictedFeature';
import { SignInWithBiometricToggle } from '../components/SignInWithBiometricToggle';
import type { TpKycApprovalStatusData } from '../data/useKycApprovalStatusData';

export type PpSettingsScene = {
  avatarAlt?: string;
  avatarContent: string | undefined;
  backTo: string;
  btcUnit?: string;
  displayCurrency?: TpFiatCurrencyUI;
  email?: string;
  kycApprovalStatusData?: TpKycApprovalStatusData;
  lnAddress?: string;
  pageTitle: string;
  paymentCurrency?: TpFiatCurrencyUI;
  primaryCurrency?: TpCryptoCurrencyUI | TpFiatCurrencyUI;
  referralUrl: string | undefined;
  usernameDisplay?: Maybe<string>;
};

export function SettingsScene({
  avatarAlt,
  avatarContent,
  backTo,
  btcUnit,
  displayCurrency,
  email,
  kycApprovalStatusData,
  lnAddress,
  pageTitle,
  paymentCurrency,
  primaryCurrency,
  referralUrl,
  usernameDisplay,
}: PpSettingsScene): React.ReactElement {
  const showAddressDialog = useRef<TpDialogToggle>(null);
  const { track } = useAnalytics();

  return (
    <AppContainer>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <AppMain>
        <AppHeader backTo={backTo} />
        <SceneMain dense dataQa="settings">
          <RestrictedFeature needs={[[Feature.LnInvoice, Feature.LnPayment]]}>
            <LightningAddressDialog ref={showAddressDialog} />
          </RestrictedFeature>

          <Stack spacing={3}>
            <Stack spacing={1}>
              <Stack alignItems="flex-start" direction="row" spacing={2}>
                <Stack
                  direction="column"
                  flexGrow={1}
                  // minWidth: 0 is needed to prevent the text from overflowing, see: https://css-tricks.com/flexbox-truncated-text/
                  spacing={1}
                  sx={{ minWidth: 0 }}
                >
                  <Typography noWrap variant="subHeadingM">
                    <TextOrSkeleton>{usernameDisplay}</TextOrSkeleton>
                  </Typography>
                  <RestrictedFeature needs={[[Feature.LnInvoice, Feature.LnPayment]]}>
                    <Stack alignItems="center" direction="row">
                      <SceneParagraph>Your NOAH Lightning Address</SceneParagraph>
                      <IconButton onClick={(): void => showAddressDialog.current?.toggle()}>
                        <InfoIcon />
                      </IconButton>
                    </Stack>
                  </RestrictedFeature>
                </Stack>
                <DefaultAvatar alt={avatarAlt} size={5.25} userInitials={avatarContent} />
              </Stack>

              <RestrictedFeature needs={[[Feature.LnInvoice, Feature.LnPayment]]}>
                <LnUrlChip lnAddress={lnAddress} />
              </RestrictedFeature>
            </Stack>

            <ListSection>
              <RestrictedFeature needs={[Feature.UIRewards]}>
                <Stack marginBottom={1}>
                  <ReferFriendsSocialShareCard
                    referralUrl={referralUrl}
                    shareButtons={shareButtons}
                  />
                </Stack>
              </RestrictedFeature>

              <PrimaryMenuItem
                className={webConfigBrowser.intercom.customLauncherSelector}
                dataQa={webConfigBrowser.intercom.customLauncherSelector}
                // use an empty onclick to force use of a button
                onClick={(): void => {}}
              >
                <PrimaryMenuIcon>
                  <FaqIcon />
                </PrimaryMenuIcon>
                <DefaultListItemText primary="Help" />
              </PrimaryMenuItem>

              <RestrictedFeature needs={[Feature.UIPromotions]}>
                <PrimaryMenuItem
                  chevron={false}
                  href={webLinks.social.twitter}
                  onClick={(): void =>
                    track(TpAnalyticsEvent.SocialCardClicked, {
                      socialMedia: 'Twitter',
                    })
                  }
                >
                  <PrimaryMenuIcon>
                    <TwitterIcon />
                  </PrimaryMenuIcon>
                  <DefaultListItemText
                    primary="Follow us on X"
                    secondary="Join the community and receive a share of the millions in sats given away on X (formerly known as Twitter)."
                    secondaryTypographyProps={{
                      noWrap: false,
                    }}
                  />
                </PrimaryMenuItem>

                <PrimaryMenuItem
                  chevron={false}
                  href={webLinks.social.discord}
                  onClick={(): void =>
                    track(TpAnalyticsEvent.SocialCardClicked, {
                      socialMedia: 'Discord',
                    })
                  }
                >
                  <PrimaryMenuIcon>
                    <DiscordIcon />
                  </PrimaryMenuIcon>
                  <DefaultListItemText
                    primary="Join the Discord community"
                    secondary="Stay in the loop with the latest news on NOAH"
                  />
                </PrimaryMenuItem>
              </RestrictedFeature>
            </ListSection>

            {/* General */}
            <ListSection title="General" titleColor="text.light">
              <PrimaryMenuItem
                dataQa="display-currency-button"
                href={userRoutes.settings.displayCurrency}
              >
                {displayCurrency?.svg && (
                  <PrimaryMenuIcon>
                    <MuiSvgIcon svg={displayCurrency.svg} />
                  </PrimaryMenuIcon>
                )}
                <DefaultListItemText
                  primary="Display currency"
                  secondary={<TextOrSkeleton>{displayCurrency?.code}</TextOrSkeleton>}
                />
              </PrimaryMenuItem>
              <RestrictedFeature needs={[[Feature.Buy, Feature.Sell]]}>
                <PrimaryMenuItem
                  dataQa="payment-currency-button"
                  href={userRoutes.settings.paymentCurrency}
                >
                  {paymentCurrency?.svg && (
                    <PrimaryMenuIcon>
                      <MuiSvgIcon svg={paymentCurrency.svg} />
                    </PrimaryMenuIcon>
                  )}
                  <DefaultListItemText
                    primary="Payment currency"
                    secondary={<TextOrSkeleton>{paymentCurrency?.code}</TextOrSkeleton>}
                  />
                </PrimaryMenuItem>
              </RestrictedFeature>
              <PrimaryMenuItem dataQa="bitcoin-unit-button" href={userRoutes.settings.bitcoinUnit}>
                <PrimaryMenuIcon>
                  <BitcoinIcon />
                </PrimaryMenuIcon>
                <DefaultListItemText
                  primary="Bitcoin unit"
                  secondary={<TextOrSkeleton>{btcUnit}</TextOrSkeleton>}
                />
              </PrimaryMenuItem>
              <PrimaryMenuItem
                dataQa="primary-currency-button"
                href={userRoutes.settings.primaryCurrency}
              >
                {primaryCurrency?.svg && (
                  <PrimaryMenuIcon>
                    <MuiSvgIcon svg={primaryCurrency.svg} />
                  </PrimaryMenuIcon>
                )}
                <DefaultListItemText
                  primary="Primary currency"
                  secondary={<TextOrSkeleton>{primaryCurrency?.code}</TextOrSkeleton>}
                />
              </PrimaryMenuItem>
              <PrimaryMenuItem dataQa="account-limits" href={userRoutes.settings.accountLimits}>
                <PrimaryMenuIcon>
                  <AccountLimitsIcon />
                </PrimaryMenuIcon>
                <DefaultListItemText primary="Account limits" />
              </PrimaryMenuItem>
              <PrimaryMenuItem dataQa="payment-methods" href={userRoutes.settings.paymentMethods}>
                <PrimaryMenuIcon>
                  <CreditCardIcon />
                </PrimaryMenuIcon>
                <DefaultListItemText primary="Payment methods" />
              </PrimaryMenuItem>
            </ListSection>

            {/* Profile */}
            <ListSection title="Profile" titleColor="text.light">
              <PrimaryMenuItem dataQa="personal-detail-button" href={userRoutes.profile}>
                <PrimaryMenuIcon>
                  <ProfileIcon />
                </PrimaryMenuIcon>
                <DefaultListItemText primary="Personal details" />
              </PrimaryMenuItem>
              <PrimaryMenuItem dataQa="linked-accounts-button" href={userRoutes.accounts}>
                <PrimaryMenuIcon>
                  <LinkIcon />
                </PrimaryMenuIcon>
                <DefaultListItemText primary="Linked accounts" />
              </PrimaryMenuItem>
            </ListSection>

            {/* Security */}
            <ListSection title="Security" titleColor="text.light">
              <PrimaryMenuItem dataQa="privacy-consent" href={userRoutes.settings.consent}>
                <PrimaryMenuIcon>
                  <EyeIcon />
                </PrimaryMenuIcon>
                <DefaultListItemText primary="Privacy" />
              </PrimaryMenuItem>
              <RestrictedFeature needs={[Feature.Pin]}>
                <PrimaryMenuItem dataQa="security-pin" href={userRoutes.settings.securityPin}>
                  <PrimaryMenuIcon>
                    <LockIcon />
                  </PrimaryMenuIcon>
                  <DefaultListItemText primary="Security PIN" />
                </PrimaryMenuItem>
              </RestrictedFeature>
              <RestrictedFeature needs={[Feature.Kyc]}>
                <PrimaryMenuItem dataQa="kyc" href={kycRoutes.base}>
                  <PrimaryMenuIcon>
                    {kycApprovalStatusData?.Icon ?? <KeyholeIcon />}
                  </PrimaryMenuIcon>
                  <DefaultListItemText
                    primary="Identity verification"
                    secondary={kycApprovalStatusData?.description}
                    {...(kycApprovalStatusData?.color
                      ? { secondaryTypographyProps: { color: kycApprovalStatusData.color } }
                      : {})}
                  />
                </PrimaryMenuItem>
              </RestrictedFeature>
              <SignInWithBiometricToggle />
            </ListSection>

            {/* About Us */}
            <ListSection title="About Us" titleColor="text.light">
              <PrimaryMenuItem dataQa="terms-button" href={webLinks.termsAndConditions.path}>
                <PrimaryMenuIcon>
                  <InfoIcon />
                </PrimaryMenuIcon>
                <DefaultListItemText primary="Terms and conditions" />
              </PrimaryMenuItem>
              <PrimaryMenuItem dataQa="privacy-button" href={webLinks.privacyPolicy.path}>
                <PrimaryMenuIcon>
                  <DocumentIcon />
                </PrimaryMenuIcon>
                <DefaultListItemText primary="Privacy Policy" />
              </PrimaryMenuItem>
            </ListSection>

            {/* Logout */}
            <ListSection
              sx={{
                // needs extra specificity to override the stack margin
                '&&': {
                  mt: 7,
                },
              }}
            >
              <PrimaryMenuItem dataQa="logout-button" href={authRoutes.signOut}>
                <PrimaryMenuIcon>
                  <LogoutIcon color="error" />
                </PrimaryMenuIcon>
                <DefaultListItemText primary="Log out" secondary={email} />
              </PrimaryMenuItem>
            </ListSection>
          </Stack>
        </SceneMain>
      </AppMain>
    </AppContainer>
  );
}
