import { css } from '@emotion/react';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  AppContainer,
  AppMain,
  ProgressBar,
  QuestBadge,
  SceneMain,
  TextOrSkeleton,
} from '@noah-labs/fe-shared-ui-components';
import type { QuestStatus } from '@noah-labs/shared-schema-gql';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';
import { FlashTimer } from '../components/flashTimer/FlashTimer';
import { QuestPageBadgeAvatar } from '../components/quest/QuestPageBadgeAvatar/QuestPageBadgeAvatar';
import { QuestTaskCard } from '../components/quest/QuestTaskCard/QuestTaskCard';
import { getQuestTotalReward, isQuestPerpetual } from '../data/utils';
import { questDescriptions, questNames } from '../mappings/questCopy';

export type PpQuestScene = {
  questStatus: QuestStatus;
};

export function QuestScene({ questStatus }: PpQuestScene): React.ReactElement {
  const theme = useTheme();
  const styles = {
    badge: css`
      min-width: 300px;
      min-height: 200px;
      max-width: 300px;
    `,
    progressBarContainer: css`
      width: 100%;
      align-items: center;
      margin: ${theme.spacing(1, 0, 4)};
    `,
  };

  const questIsPerpetual = isQuestPerpetual(questStatus.Quest.QuestID);

  const questTotalNp = getQuestTotalReward(questStatus.Quest);

  const questTaskNp = questTotalNp / questStatus.RequiredProgress;

  const currentTask = questStatus.Tasks?.[questStatus.Progress];

  return (
    <AppContainer backgroundColor={theme.palette.grayscale[900]}>
      <Helmet>
        <title>{questNames[questStatus.Quest.QuestID]}</title>
      </Helmet>
      <AppMain sx={{ overflow: 'hidden' }}>
        <AppHeaderData
          backButton
          disableFade
          helpButton
          backgroundColor="transparent"
          fontColor={theme.palette.primaryLight.main}
        />
        <SceneMain dense dataQa="quest">
          <Stack spacing={3} sx={{ alignItems: 'center', mb: 4, width: '100%' }}>
            <QuestPageBadgeAvatar
              BadgeSlot={
                <QuestBadge badgeId={questStatus.Quest.BadgeTypeID} imageCss={styles.badge} />
              }
              np={questIsPerpetual ? questTaskNp : questTotalNp}
              questName={questNames[questStatus.Quest.QuestID]}
            />
            {questStatus.Quest.Ends && (
              <FlashTimer
                ends={questStatus.Quest.Ends}
                sx={{ borderRadius: theme.borderRadius.sm, width: '100%', zIndex: 1 }}
              />
            )}
            {questDescriptions[questStatus.Quest.QuestID] && (
              <Typography color="grayscale.white80" textAlign="center">
                <TextOrSkeleton>{questDescriptions[questStatus.Quest.QuestID]}</TextOrSkeleton>
              </Typography>
            )}
            {!questIsPerpetual && (
              <Stack css={styles.progressBarContainer} direction="row" spacing={2}>
                <ProgressBar progress={questStatus.Progress / questStatus.RequiredProgress} />
                <Typography color="grayscale.white80" variant="paragraphBodyS">
                  {questStatus.Progress}/{questStatus.RequiredProgress}
                </Typography>
              </Stack>
            )}
          </Stack>
          <Stack spacing={2}>
            {questIsPerpetual && currentTask ? (
              <QuestTaskCard task={currentTask} />
            ) : (
              questStatus.Tasks?.map((task) => (
                <QuestTaskCard key={task.Task.QuestTaskID} task={task} />
              ))
            )}
          </Stack>
        </SceneMain>
      </AppMain>
    </AppContainer>
  );
}
