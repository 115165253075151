import React from 'react';
import {
  AppContainer,
  AppHeaderTitle,
  AppMain,
  DefaultListItemText,
  List,
  PrimaryMenuItem,
  SceneMain,
} from '@noah-labs/fe-shared-ui-components';
import { userRoutes } from '@noah-labs/fe-shared-util-routes';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';

type PpSecurityPinScene = {
  backTo?: string;
  pinSetupRequired: boolean;
};

export function SecurityPinScene({
  backTo,
  pinSetupRequired,
}: PpSecurityPinScene): React.ReactElement {
  return (
    <AppContainer>
      <Helmet>
        <title>Security PIN</title>
      </Helmet>
      <AppMain>
        <AppHeaderData helpButton backTo={backTo}>
          <AppHeaderTitle>Security PIN</AppHeaderTitle>
        </AppHeaderData>
        <SceneMain dense>
          <List disablePadding>
            {pinSetupRequired && (
              <PrimaryMenuItem href={userRoutes.pin.create.pin}>
                <DefaultListItemText primary="Create PIN" secondary="Create a new security PIN" />
              </PrimaryMenuItem>
            )}

            {!pinSetupRequired && (
              <React.Fragment>
                <PrimaryMenuItem href={userRoutes.pin.update.currentPin}>
                  <DefaultListItemText
                    primary="Update PIN"
                    secondary="You will be asked for your current PIN"
                  />
                </PrimaryMenuItem>

                <PrimaryMenuItem href={userRoutes.pin.reset.secretPhrase}>
                  <DefaultListItemText
                    primary="Forgot PIN"
                    secondary="Use your recovery phrase to reset your PIN"
                  />
                </PrimaryMenuItem>
              </React.Fragment>
            )}
          </List>
        </SceneMain>
      </AppMain>
    </AppContainer>
  );
}
