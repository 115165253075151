import React, { useCallback, useEffect, useRef } from 'react';
import type { TpUseWithdrawFeeWithTransaction } from '@noah-labs/fe-shared-data-access-wallet';
import {
  useWithdrawFeeWithTransaction,
  useWithdrawOrderCreateMutation,
} from '@noah-labs/fe-shared-data-access-wallet';
import { TpAnalyticsEvent, useAnalytics } from '@noah-labs/fe-shared-feature-analytics';
import { useSignWithdrawal } from '@noah-labs/fe-shared-feature-signing';
import { useUserInitUi, useUserWithdrawalAllowance } from '@noah-labs/fe-shared-feature-user';
import { generatePath } from '@noah-labs/fe-shared-ui-components';
import type { TpStateMachine } from '@noah-labs/fe-shared-ui-components';
import type { TpDialogToggle } from '@noah-labs/fe-shared-ui-shared';
import { useRouter, useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import {
  ConfirmScene,
  getCurrencyAmountSlots,
  NetworkFeeItem,
  useWalletError,
  WithdrawNetworkFeeDialog,
} from '@noah-labs/fe-shared-ui-wallet';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { TransferDestinationType } from '@noah-labs/shared-schema-gql';
import { useSendAmounts } from '../../hooks/useSendAmounts';
import type { PpOnSubmitWithdrawOrder } from '../../hooks/useWithdrawNewSignature';
import { useWithdrawNewSignature } from '../../hooks/useWithdrawNewSignature';
import { getFeeQuoteExpiresIn } from '../../utils/getFeeQuoteExpiresIn';
import { getWithdrawFeeAmounts } from '../../utils/getWithdrawFeeAmounts';
import type { SmWithdraw } from '../types';

export function WithdrawConfirmController({
  state,
  updateState,
}: Pick<TpStateMachine<SmWithdraw>, 'state' | 'updateState'>): React.ReactElement {
  const { push } = useRouter();
  const { AccountType, cryptoCurrency, cryptoNetwork, params } = useWalletParams();
  const { error, isLoading, mutateAsync } = useWithdrawOrderCreateMutation();
  const { data: userData } = useUserInitUi();
  const { track } = useAnalytics();

  const currentFeeDataRef = useRef<TpUseWithdrawFeeWithTransaction>();
  const feeIncreasedDialogRef = useRef<TpDialogToggle>(null);

  const allowance = useUserWithdrawalAllowance({
    accountType: AccountType,
    cryptoCurrency,
    cryptoNetwork,
  });

  const { fetchedAt, fiatAmount, price } = useSendAmounts({
    cryptoAmount: state.cryptoAmount,
    cryptoCurrency,
    fiatAmount: state.fiatAmount,
  });

  useEffect(() => {
    updateState({
      fetchedAt,
      fiatAmount,
      price,
    });
  }, [fetchedAt, fiatAmount, price, updateState]);

  const onSubmit = useCallback(
    async ({ cryptoAmount, feeQuote, signature }: PpOnSubmitWithdrawOrder) => {
      if (!userData) {
        return;
      }

      if (!feeQuote) {
        throw new Error('Invalid fee quote');
      }

      try {
        const data = await mutateAsync({
          Input: {
            AccountType,
            Amount: cryptoAmount,
            CurrencyCode: cryptoCurrency.code,
            Destination: {
              DestinationAddress: {
                // can use 'as' because of guard above
                Address: state.payeeData?.address as string,
              },
              DestinationType: TransferDestinationType.Address,
            },
            FeeQuote: feeQuote,
            ...(signature && { Nonce: signature.nonce, Signature: signature.signature }),
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            Network: cryptoNetwork!.id,
            RequestedAmount: {
              Amount: state.fiatAmount,
              FetchedAt: state.fetchedAt,
              FiatCurrency: userData.userProfile.fiatCurrency.code,
              Price: state.price,
            },
          },
        });
        const { EventId: withdrawOrderId } = data.withdrawOrderCreate.Entries[0];
        updateState({
          withdrawOrderId,
        });
        push(generatePath(walletRoutes().send.complete, params));
      } catch (e) {
        // useWalletError handles error
      }
    },
    [
      AccountType,
      cryptoCurrency,
      cryptoNetwork,
      mutateAsync,
      params,
      push,
      state.fetchedAt,
      state.fiatAmount,
      state.payeeData?.address,
      state.price,
      updateState,
      userData,
    ],
  );

  const { isSubmitWithNewSignatureLoading, onSubmitWithdrawOrder, onSubmitWithNewSignature } =
    useWithdrawNewSignature({
      AccountType,
      address: state.payeeData?.address || '',
      cryptoCurrency,
      currentFeeDataRef,
      onSubmit,
      toggleFeeIncreasedDialog: () => feeIncreasedDialogRef.current?.toggle(),
    });

  const feeData = useWithdrawFeeWithTransaction({
    address: state.payeeData?.address || '',
    cryptoAmount: state.cryptoAmount,
    cryptoCurrency,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    cryptoNetwork: cryptoNetwork!,

    enabled: !isSubmitWithNewSignatureLoading && !isLoading,

    fiatCurrency: userData?.userProfile.fiatCurrency,
  });
  currentFeeDataRef.current = feeData;

  const totalAmounts = getWithdrawFeeAmounts({
    cryptoAmount: state.cryptoAmount,
    feeCryptoAmount: feeData.feeCryptoAmount,
    feeFiatAmount: feeData.feeFiatAmount,
    fiatAmount: state.fiatAmount,
  });

  const sufficientBalance =
    feeData.isFeeFetched && allowance?.balanceUserCrypto?.gte(totalAmounts.cryptoAmountWithFee);

  const FeeAmounts = getCurrencyAmountSlots({
    cryptoAmount: feeData.feeCryptoAmount,
    cryptoCurrency,
    cryptoUnit: userData?.userProfile.DisplayUnit,
    fiatAmount: feeData.feeCryptoAmount ? feeData.feeFiatAmount : undefined,
    fiatCurrency: userData?.userProfile.fiatCurrency,
    primaryCurrency: userData?.userProfile.PrimaryCurrency,
    roundDown: true,
  });

  const TotalAmounts = getCurrencyAmountSlots({
    cryptoAmount: feeData.isFeeFetched ? totalAmounts.cryptoAmountWithFee : undefined,
    cryptoCurrency,
    cryptoUnit: userData?.userProfile.DisplayUnit,
    fiatAmount: feeData.isFeeFetched ? totalAmounts.fiatAmountWithFee : undefined,
    fiatCurrency: userData?.userProfile.fiatCurrency,
    primaryCurrency: userData?.userProfile.PrimaryCurrency,
    roundDown: true,
  });

  const { loading: signLoading, sign } = useSignWithdrawal({
    cryptoNetwork,
    payload: {
      AccountType,
      Amount: totalAmounts.cryptoAmountWithFee,
      CurrencyCode: cryptoCurrency.code,
      Destination: state.payeeData?.address || '',
      inputType: 'withdraw',
      NetworkFee: feeData.feeCryptoAmount,
    },
  });

  const onConfirm = useCallback(async () => {
    track(TpAnalyticsEvent.WithdrawOrderStarted);
    await sign((signPayload) =>
      onSubmitWithdrawOrder({
        cryptoAmount: state.cryptoAmount,
        cryptoAmountWithFee: totalAmounts.cryptoAmountWithFee,
        feeFiatAmount: feeData.feeFiatAmount,
        feeQuote: feeData.feeQuote,
        feeQuoteExpiresIn: getFeeQuoteExpiresIn(feeData.feeFetchedAt),
        fiatAmount: state.fiatAmount,
        pin: signPayload.pin,
        signature: signPayload.signature,
      }),
    );
  }, [
    sign,
    feeData.feeFiatAmount,
    feeData.feeFetchedAt,
    feeData.feeQuote,
    state.cryptoAmount,
    state.fiatAmount,
    totalAmounts.cryptoAmountWithFee,
    onSubmitWithdrawOrder,
    track,
  ]);

  const { ApiErrorScene } = useWalletError(error || feeData.feeError);
  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <React.Fragment>
      <ConfirmScene
        addressData={state.payeeData}
        cryptoAmount={state.cryptoAmount}
        cryptoCurrency={cryptoCurrency}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        cryptoNetwork={cryptoNetwork!}
        cryptoUnit={userData?.userProfile.DisplayUnit}
        FeeSlot={<NetworkFeeItem cryptoCurrency={cryptoCurrency} {...FeeAmounts} />}
        fiatAmount={state.fiatAmount}
        fiatCurrency={userData?.userProfile.fiatCurrency}
        isCtaDisabled={signLoading || !sufficientBalance}
        isLoading={isLoading}
        primaryCurrency={userData?.userProfile.PrimaryCurrency}
        TotalAmountSlots={TotalAmounts}
        onConfirm={onConfirm}
      />
      <WithdrawNetworkFeeDialog
        ref={feeIncreasedDialogRef}
        FeeAmountSlots={FeeAmounts}
        isLoading={isSubmitWithNewSignatureLoading}
        TotalAmountSlots={TotalAmounts}
        onContinue={(): Promise<void> =>
          onSubmitWithNewSignature({
            cryptoAmount: totalAmounts.cryptoAmountWithFee,
            feeQuote: feeData.feeQuote,
            networkFee: feeData.feeCryptoAmount,
          })
        }
      />
    </React.Fragment>
  );
}
