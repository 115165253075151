import {
  AppContainer,
  AppHeaderTitle,
  AppMain,
  DefaultListItemText,
  ElevatedCardListItem,
  List,
  SceneMain,
} from '@noah-labs/fe-shared-ui-components';
import type { TpSkeletonText } from '@noah-labs/fe-shared-ui-shared';
import type { FullName, Maybe } from '@noah-labs/shared-schema-gql';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';

export type PpProfileDetailsScene = {
  email: TpSkeletonText;
  personalName?: Maybe<FullName>;
};
export function ProfileDetailsScene({
  email,
  personalName,
}: PpProfileDetailsScene): React.ReactElement {
  const name = personalName
    ? [personalName.FirstName, personalName.MiddleName, personalName.LastName]
        .filter(Boolean)
        .join(' ')
    : '-';

  return (
    <AppContainer>
      <Helmet>
        <title>Personal details</title>
      </Helmet>
      <AppMain>
        <AppHeaderData backButton helpButton>
          <AppHeaderTitle>Personal details</AppHeaderTitle>
        </AppHeaderData>
        <SceneMain dense dataQa="personal-details-menu">
          <List disablePadding>
            <ElevatedCardListItem dataQa="menu-list-item--personal-name">
              <DefaultListItemText primary="Name" secondary={name} />
            </ElevatedCardListItem>

            <ElevatedCardListItem dataQa="menu-list-item--email">
              <DefaultListItemText primary="Email" secondary={email} />
            </ElevatedCardListItem>
          </List>
        </SceneMain>
      </AppMain>
    </AppContainer>
  );
}
