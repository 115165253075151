import { useEffect, useState } from 'react';
import { disableRefetchRetry } from '@noah-labs/fe-shared-data-access-shared';
import { useLnAddressProxyQuery } from '@noah-labs/fe-shared-data-access-wallet';
import { useUserWithdrawalAllowance } from '@noah-labs/fe-shared-feature-user';
import { type SmLightningSend, useSendAmounts } from '@noah-labs/fe-shared-feature-wallet';
import type { TpStateMachine } from '@noah-labs/fe-shared-ui-components';
import { generatePath, LoadingPage } from '@noah-labs/fe-shared-ui-components';
import type { TpLightningAddressData } from '@noah-labs/fe-shared-ui-components/crypto';
import { parseAddressData } from '@noah-labs/fe-shared-ui-components/crypto';
import { useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { LightningError, useWalletError } from '@noah-labs/fe-shared-ui-wallet';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { btcToSats, satsToBtc } from '@noah-labs/shared-currencies';
import { CurrencyUnit } from '@noah-labs/shared-schema-gql';
import { isUndefinedOrNull, safeBN } from '@noah-labs/shared-util-vanilla';
import { Redirect, Route, useParams } from 'react-router-dom';
import { isProd } from '../../../../webConfigBrowser';
import type { TpExternalRequest, TpExternalRequestParams } from './types';

// const mockSend = 'eyJhbW91bnQiOiIxMDAwIiwiYWRkcmVzcyI6InN1a2hAbm9haC5tZSJ9'
// {"amount":"1000","address":"sukh@noah.me"}

// const mockSendWithDescription = 'eyJhbW91bnQiOiIxMDAwIiwiYWRkcmVzcyI6InN1a2hAbm9haC5tZSIsImRlc2NyaXB0aW9uIjoiTk9BSCBwcml6ZSB3aW4hIn0='
// {"amount":"1000","address":"sukh@noah.me","description":"NOAH prize win!"}

// e.g. url
// http://localhost:8000/wallet/btc_test/current/lightning-send/request/Discord/eyJhbW91bnQiOiIxMDAwIiwiYWRkcmVzcyI6InN1a2hAbm9haC5tZSJ9

export function ExternalRequest({
  state,
  updateState,
}: TpStateMachine<SmLightningSend>): React.ReactElement {
  const [error, setError] = useState<Error>();
  const [cryptoAmountSats, setCryptoAmountSats] = useState<string>('');
  const [paymentRequestData, setPaymentRequestData] = useState<TpLightningAddressData | undefined>(
    undefined,
  );
  const { AccountType, cryptoCurrency, cryptoNetwork, params } = useWalletParams();
  const { encodedSendParams, source } = useParams<TpExternalRequestParams>();

  // decode and parse the address and store it in state
  useEffect(() => {
    try {
      const sendData: TpExternalRequest = JSON.parse(atob(encodedSendParams));

      const amount = satsToBtc(sendData.amount);

      // Amount from params will always be in sats, so convert to btc
      setCryptoAmountSats(amount);

      const parsedData = {
        ...parseAddressData(sendData.address, isProd),
        description: [sendData.description, source].filter(Boolean).join(' via '),
      } as TpLightningAddressData | undefined;

      setPaymentRequestData(parsedData);
    } catch (err) {
      setError(err as Error);
    }
  }, [encodedSendParams, source]);

  const { data: lnAddressProxy, error: lnAddressProxyError } = useLnAddressProxyQuery(
    {
      Input: {
        LightningAddress: paymentRequestData?.address || '',
      },
    },
    {
      enabled: paymentRequestData?.addressType === 'lnaddress',
      ...disableRefetchRetry,
    },
  );

  const { cryptoUnit, fetchedAt, fiatAmount, price } = useSendAmounts({
    cryptoAmount: cryptoAmountSats,
    cryptoCurrency,
  });

  const allowance = useUserWithdrawalAllowance({
    accountType: AccountType,
    cryptoCurrency,
    cryptoNetwork,
  });

  const lnAllowanceCrypto = allowance?.lnAllowanceCrypto;
  const allowed = lnAllowanceCrypto?.amount.toString();
  const allowanceReason = lnAllowanceCrypto?.reason;
  const lnAddressProxyData = lnAddressProxy?.lightningAddressProxy;

  useEffect((): void => {
    if (
      isUndefinedOrNull(allowed) ||
      isUndefinedOrNull(allowanceReason) ||
      isUndefinedOrNull(cryptoAmountSats) ||
      isUndefinedOrNull(lnAddressProxyData)
    ) {
      return;
    }
    try {
      if (lnAddressProxyData.__typename === 'LnurlError') {
        throw new LightningError(lnAddressProxyData.Reason);
      }

      const cryptoAmountBN = safeBN(cryptoAmountSats);

      if (cryptoAmountBN.gt(allowed)) {
        switch (allowanceReason) {
          case 'PolicyLimit':
            throw new LightningError(
              'Sorry, you’ve reached your withdrawal limits.',
              'Limit exceeded',
            );
          case 'Balance':
            throw new LightningError(
              'Sorry, you don’t have enough funds for this.',
              'Balance exceeded',
            );
          default:
        }
      }

      const { MaxSendable, MinSendable } = lnAddressProxyData;

      const maxToSats = btcToSats(MaxSendable);
      const minToSats = btcToSats(MinSendable);
      const errorTitle = 'Invalid amount';

      if (cryptoAmountBN.lt(MinSendable)) {
        throw new LightningError(`Minimum amount ${minToSats} ${CurrencyUnit.SATS}`, errorTitle);
      }

      if (cryptoAmountBN.gt(MaxSendable)) {
        throw new LightningError(`Maximum amount ${maxToSats} ${CurrencyUnit.SATS}`, errorTitle);
      }

      updateState({
        cryptoAmount: cryptoAmountSats,
        cryptoUnit,
        fetchedAt,
        fiatAmount,
        paymentRequestData,
        price,
      });
    } catch (err) {
      setError(err as Error);
    }
  }, [
    cryptoUnit,
    fetchedAt,
    fiatAmount,
    paymentRequestData,
    price,
    lnAddressProxyData,
    updateState,
    cryptoAmountSats,
    allowed,
    allowanceReason,
  ]);

  const { ApiErrorScene } = useWalletError(error || lnAddressProxyError, true);

  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  const { paymentRequestData: stPaymentRequestData } = state;

  if (!stPaymentRequestData) {
    return <LoadingPage />;
  }

  return (
    <Route
      // eslint-disable-next-line react/no-unstable-nested-components
      component={(): React.ReactElement => (
        <Redirect to={generatePath(walletRoutes().send.lightning.confirm, params)} />
      )}
    />
  );
}
