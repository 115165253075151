import { getConfig } from '@noah-labs/fe-shared-data-access-wallet';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import type { TpStateMachine } from '@noah-labs/fe-shared-ui-components';
import { parseAddressData } from '@noah-labs/fe-shared-ui-components/crypto';
import { useRouter, useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { QRDisplayScene } from '@noah-labs/fe-shared-ui-wallet';
import { webLinks } from '@noah-labs/shared-constants';
import type { SmLightningInvoice } from '../types';

export function LightningReceiveViewInvoiceController({
  resetState,
  state,
}: Pick<TpStateMachine<SmLightningInvoice>, 'resetState' | 'state'>): React.ReactElement {
  const { isProd } = getConfig();
  const { cryptoCurrency } = useWalletParams();
  const { data: userData } = useUserInitUi();
  const { useOnRouteChange } = useRouter();

  /**
   * End of the flow so reset the flow state machine
   */
  useOnRouteChange(resetState);

  const paymentRequestData = parseAddressData(state.paymentRequest, isProd);

  return (
    <QRDisplayScene
      addressData={paymentRequestData}
      cryptoAmount={paymentRequestData.amount}
      cryptoCurrency={cryptoCurrency}
      cryptoUnit={userData?.userProfile.DisplayUnit}
      faqLink={webLinks.faq.transactionTimes}
      fiatAmount={state.fiatAmount}
      fiatCurrency={userData?.userProfile.fiatCurrency}
      primaryCurrency={userData?.userProfile.PrimaryCurrency}
      transactionId={state.publicID}
    />
  );
}
