/* eslint-disable no-param-reassign */
import type { UiNode } from '@ory/client';
import { findOryUiNode } from './findOryUiNode';
import { oryWebAuthnBufferDecode } from './utils';

const WEBAUTHN_SCRIPT_LOGIN_TRIGGER_PREFIX = 'window.oryWebAuthnLogin';
const WEBAUTHN_SCRIPT_REGISTER_TRIGGER_PREFIX = 'window.oryWebAuthnRegistration';

function isNonNullableObject(value: unknown): value is object {
  if (typeof value === 'object' && value !== null) {
    return true;
  }
  return false;
}

function decodeOptions(options: unknown): unknown {
  if (!isNonNullableObject(options)) {
    return options;
  }

  if (!('publicKey' in options && isNonNullableObject(options.publicKey))) {
    return options;
  }

  if ('challenge' in options.publicKey && typeof options.publicKey.challenge === 'string') {
    options.publicKey.challenge = oryWebAuthnBufferDecode(options.publicKey.challenge);
  }

  if (
    'allowCredentials' in options.publicKey &&
    Array.isArray(options.publicKey.allowCredentials)
  ) {
    options.publicKey.allowCredentials = options.publicKey.allowCredentials.map((c: unknown) => {
      if (!isNonNullableObject(c) || !('id' in c) || typeof c.id !== 'string') {
        return c;
      }

      c.id = oryWebAuthnBufferDecode(c.id);
      return c;
    });
  }

  if (
    'excludeCredentials' in options.publicKey &&
    Array.isArray(options.publicKey.excludeCredentials)
  ) {
    options.publicKey.excludeCredentials = options.publicKey.excludeCredentials.map(
      (c: unknown) => {
        if (!isNonNullableObject(c) || !('id' in c) || typeof c.id !== 'string') {
          return c;
        }

        c.id = oryWebAuthnBufferDecode(c.id);
        return c;
      },
    );
  }

  if (
    'user' in options.publicKey &&
    isNonNullableObject(options.publicKey.user) &&
    'id' in options.publicKey.user &&
    typeof options.publicKey.user.id === 'string'
  ) {
    options.publicKey.user.id = oryWebAuthnBufferDecode(options.publicKey.user.id);
  }

  return options;
}

export function getOryWebAuthnOptions(
  nodes: UiNode[],
  type: 'creation',
): CredentialCreationOptions | undefined;
export function getOryWebAuthnOptions(
  nodes: UiNode[],
  type: 'request',
): CredentialRequestOptions | undefined;
export function getOryWebAuthnOptions(nodes: UiNode[], type: string): unknown | undefined {
  const { nodeName, prefix } =
    type === 'request'
      ? { nodeName: 'passkey_login_trigger', prefix: WEBAUTHN_SCRIPT_LOGIN_TRIGGER_PREFIX }
      : { nodeName: 'passkey_register_trigger', prefix: WEBAUTHN_SCRIPT_REGISTER_TRIGGER_PREFIX };

  const loginNode = findOryUiNode(nodes, nodeName);
  if (!loginNode || !('onclick' in loginNode.attributes)) {
    return undefined;
  }

  const loginOnClick = loginNode.attributes.onclick;
  if (!loginOnClick?.startsWith(prefix)) {
    return undefined;
  }

  const optionsString = loginOnClick.slice(prefix.length + 1, loginOnClick.length - 1);
  const options = JSON.parse(optionsString);

  return decodeOptions(options);
}
