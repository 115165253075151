import { fiatCurrencyFromCode, getEnvCryptoCurrencyFromCode } from '@noah-labs/fe-shared-ui-shared';
import type { TpCryptoCurrencyUI, TpFiatCurrencyUI } from '@noah-labs/fe-shared-ui-shared';
import { AccountType, CurrencyCode, FiatCurrencyCode } from '@noah-labs/shared-schema-gql';
import { isProd } from '../webConfigBrowser';

type TpDefaults = {
  accountType: AccountType;
  cryptoCurrency: TpCryptoCurrencyUI;
  fiatCurrency: TpFiatCurrencyUI;
};

export function getDefaults(): TpDefaults {
  return {
    accountType: AccountType.Current,
    cryptoCurrency: getEnvCryptoCurrencyFromCode(CurrencyCode.BTC, isProd),
    fiatCurrency: fiatCurrencyFromCode(FiatCurrencyCode.USD),
  };
}
