import { Stack } from '@mui/material';
import { OpenInWindowIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/OpenInWindowIcon';
import {
  AddressWithIcon,
  Balance,
  CryptoAmount,
  FiatAmount,
  List,
  ListItem,
  ListItemButton,
  ListItemCard,
  ListItemContent,
  ListItemEndContent,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
  TextWithIcon,
} from '@noah-labs/fe-shared-ui-components';
import type { TpTransactionUi } from '@noah-labs/fe-shared-ui-shared';
import {
  AvatarWithTransactionStatus,
  getCurrencyAmountSlots,
  TransactionSummary,
} from '@noah-labs/fe-shared-ui-wallet';
import type { TpCurrencyAmountSlots } from '@noah-labs/fe-shared-ui-wallet';
import type { CurrencyUnit } from '@noah-labs/shared-schema-gql';
import {
  CurrencyDisplayType,
  TransactionDirection,
  TransactionStatus,
} from '@noah-labs/shared-schema-gql';
import { isNonZero } from '@noah-labs/shared-util-vanilla';

type TpAmountsDisplay = {
  NetAmounts: TpCurrencyAmountSlots;
  RequestedAmounts: TpCurrencyAmountSlots;
};

type TpGetAmountsToDisplay = {
  NetAmounts: TpCurrencyAmountSlots;
  RequestedAmounts: TpCurrencyAmountSlots;
  direction: TransactionDirection | undefined | null;
  isRamp: boolean;
  primaryCurrency?: CurrencyDisplayType;
};

function getAmountsToDisplay({
  direction,
  isRamp,
  NetAmounts,
  primaryCurrency,
  RequestedAmounts,
}: TpGetAmountsToDisplay): TpAmountsDisplay {
  let NetPrimary = NetAmounts.PrimaryAmountSlot;
  let NetSecondary = NetAmounts.SecondaryAmountSlot;
  let RequestedPrimary = RequestedAmounts.PrimaryAmountSlot;
  let RequestedSecondary = RequestedAmounts.SecondaryAmountSlot;

  const isPrimaryCrypto = primaryCurrency === CurrencyDisplayType.Crypto;

  // Buys
  if (isRamp && direction === TransactionDirection.In) {
    if (isPrimaryCrypto) {
      NetPrimary = NetAmounts.SecondaryAmountSlot;
      NetSecondary = NetAmounts.PrimaryAmountSlot;
      RequestedPrimary = RequestedAmounts.SecondaryAmountSlot;
      RequestedSecondary = undefined;
    } else {
      NetPrimary = NetAmounts.PrimaryAmountSlot;
      NetSecondary = NetAmounts.SecondaryAmountSlot;
      RequestedPrimary = RequestedAmounts.PrimaryAmountSlot;
      RequestedSecondary = undefined;
    }
  }

  // Sell
  if (isRamp && direction === TransactionDirection.Out) {
    if (isPrimaryCrypto) {
      NetPrimary = NetAmounts.PrimaryAmountSlot;
      NetSecondary = NetAmounts.SecondaryAmountSlot;
      RequestedPrimary = RequestedAmounts.PrimaryAmountSlot;
      RequestedSecondary = undefined;
    } else {
      NetPrimary = NetAmounts.SecondaryAmountSlot;
      NetSecondary = NetAmounts.PrimaryAmountSlot;
      RequestedPrimary = RequestedAmounts.PrimaryAmountSlot;
      RequestedSecondary = undefined;
    }
  }

  return {
    NetAmounts: {
      PrimaryAmountSlot: NetPrimary,
      SecondaryAmountSlot: NetSecondary,
    },
    RequestedAmounts: {
      PrimaryAmountSlot: RequestedPrimary,
      SecondaryAmountSlot: RequestedSecondary,
    },
  };
}

// Use Partial here because we want to be able to show a Skeleton state
export type PpTransactionDetails = Partial<TpTransactionUi> & {
  cryptoUnit: CurrencyUnit | undefined;
  primaryCurrency?: CurrencyDisplayType;
};
export function TransactionDetails({
  cryptoAmount,
  cryptoCurrency,
  cryptoNetwork,
  cryptoUnit,
  dataQa,
  description,
  direction,
  feeAmount,
  fiatAmount,
  fiatAmountWithFee,
  fiatCurrency,
  hasExpired,
  hasFee,
  isRamp,
  mainPrimaryContent,
  primaryCurrency,
  publicID,
  status,
  statusText,
  type,
}: PpTransactionDetails): React.ReactElement {
  // transaction amounts without fees
  const RequestedAmounts = getCurrencyAmountSlots({
    cryptoAmount,
    cryptoCurrency,
    cryptoUnit,
    fiatAmount,
    fiatCurrency,
    primaryCurrency,
    roundDown: true,
    signDisplay: 'never',
    strikeThrough: status === TransactionStatus.Failed,
  });

  const totalFiatAmount = hasFee ? fiatAmountWithFee : fiatAmount;

  // sent or received amounts
  const NetAmounts = getCurrencyAmountSlots({
    cryptoAmount,
    cryptoCurrency,
    cryptoUnit,
    fiatAmount: totalFiatAmount,
    fiatCurrency,
    nonZeroCrypto: true,
    nonZeroFiat: true,
    primaryCurrency,
    roundDown: true,
    signDisplay: 'never',
    strikeThrough: status === TransactionStatus.Failed,
  });

  const { NetAmounts: NetAmountsDisplay, RequestedAmounts: RequestedAmountsDisplay } =
    getAmountsToDisplay({
      direction,
      isRamp: Boolean(isRamp),
      NetAmounts,
      primaryCurrency,
      RequestedAmounts,
    });

  const Fee = isRamp ? (
    <FiatAmount
      roundDown
      amount={feeAmount}
      currency={fiatCurrency}
      fallback="Confirming Fee"
      fallbackCheck={(value): boolean => !isNonZero(value)}
      signDisplay="never"
    />
  ) : (
    <CryptoAmount
      roundDown
      amount={feeAmount}
      currency={cryptoCurrency}
      fallback="Confirming Fee"
      fallbackCheck={(value): boolean => !isNonZero(value)}
      signDisplay="never"
    />
  );

  const publicUrl = cryptoNetwork?.getPublicTxUrl(publicID);

  return (
    <Stack spacing={1}>
      <div>
        <TransactionSummary
          AvatarSlot={
            <AvatarWithTransactionStatus
              svg={cryptoCurrency?.svg}
              transactionStatus={status}
              transactionType={type}
            />
          }
          dataQa={dataQa}
        >
          <Stack alignItems="center">
            <AddressWithIcon svg={cryptoNetwork?.svg}>{mainPrimaryContent}</AddressWithIcon>
          </Stack>
          <Balance {...RequestedAmountsDisplay} />
        </TransactionSummary>
      </div>
      <List disablePadding spacing={1}>
        {description && (
          <ListItem data-qa="note">
            <ListItemCard>
              <ListItemContent>
                <ListItemStartContent>
                  <ListItemPrimaryText>Note</ListItemPrimaryText>
                  <ListItemSecondaryText>{description}</ListItemSecondaryText>
                </ListItemStartContent>
              </ListItemContent>
            </ListItemCard>
          </ListItem>
        )}

        <ListItem data-qa="status">
          <ListItemCard>
            <ListItemContent>
              <ListItemStartContent>
                <ListItemPrimaryText>Status</ListItemPrimaryText>
                <ListItemSecondaryText>
                  {statusText
                    ? `${
                        hasExpired && direction === TransactionDirection.In ? 'Invoice' : 'Payment'
                      } ${statusText}`
                    : undefined}
                </ListItemSecondaryText>
              </ListItemStartContent>
            </ListItemContent>
          </ListItemCard>
        </ListItem>

        <ListItem data-qa="amount">
          <ListItemCard>
            <ListItemContent>
              <ListItemStartContent>
                <ListItemPrimaryText>Amount</ListItemPrimaryText>
              </ListItemStartContent>
              <ListItemEndContent>
                <ListItemPrimaryText>{NetAmountsDisplay.PrimaryAmountSlot}</ListItemPrimaryText>
                <ListItemSecondaryText>
                  {NetAmountsDisplay.SecondaryAmountSlot}
                </ListItemSecondaryText>
              </ListItemEndContent>
            </ListItemContent>
          </ListItemCard>
        </ListItem>

        {status !== TransactionStatus.Failed && (
          <ListItem data-qa="fee">
            <ListItemCard>
              <ListItemContent>
                <ListItemStartContent>
                  <ListItemPrimaryText>Fee</ListItemPrimaryText>
                </ListItemStartContent>
                <ListItemEndContent>
                  {hasFee && <ListItemPrimaryText>{Fee}</ListItemPrimaryText>}
                  {!hasFee && <ListItemSecondaryText>No fee</ListItemSecondaryText>}
                </ListItemEndContent>
              </ListItemContent>
            </ListItemCard>
          </ListItem>
        )}

        {publicUrl && (
          <ListItem data-qa="public-txn-link">
            <ListItemButton href={publicUrl} target="_blank">
              <ListItemCard>
                <ListItemContent>
                  <ListItemStartContent>
                    <ListItemPrimaryText>Public transaction link</ListItemPrimaryText>
                  </ListItemStartContent>
                  <ListItemEndContent>
                    <TextWithIcon sx={{ color: 'text.link' }}>
                      <OpenInWindowIcon sx={{ fontSize: '1rem' }} />
                    </TextWithIcon>
                  </ListItemEndContent>
                </ListItemContent>
              </ListItemCard>
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Stack>
  );
}
